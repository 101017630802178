// src/App.tsx

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import routes from "./routes/routes";
import Footer from "./components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CookieConsentBanner from "./components/CookieConsent/CookieConsent";
// import ChatBot from "./components/ChatBot/ChatBot";
// import AccessibilityWidget from './components/accessibility/AccessibilityWidget';
import { Cookies } from "react-cookie-consent";
import { Suspense } from "react";

function App() {
  const { i18n } = useTranslation();

  const consent = Cookies.get("refaeNaamanWebsiteHasUserConsentForCookies");

  // language cookie save
  useEffect(() => {
    if (consent) {
      Cookies.set("userLanguage", i18n.language);
    }
  }, [i18n.language, consent]);

  // Add Accessibility
  useEffect(() => {
    // Create a new script tag
    const script = document.createElement("script");
    script.src = "https://cdn.userway.org/widget.js";
    // script.src = "https://website-widgets.pages.dev/dist/sienna.min.js"
    script.async = true;

    // Set attributes
    script.setAttribute("data-position", "6");
    script.setAttribute("data-size", "small");
    script.setAttribute("data-mobile", "true");
    script.setAttribute("data-language", `${i18n.language}`);
    script.setAttribute("data-type", "1");
    script.setAttribute("data-account", "6JQZ2X2o2y");

    // Remove any existing accessibility script tags
    const existingScript = document.querySelector(
      'script[src="https://cdn.userway.org/widget.js"]'
    );
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    // Append the new script tag to the body
    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [i18n.language]);

  // declare const MicAccessTool: any;

  // function App() {
  //   const { i18n } = useTranslation();

  //   // Add Accessibility
  //   useEffect(() => {
  //     const position = "right";
  //     const language = i18n.language === 'he' ? 'he-IL' : 'en'; // Uncomment this line

  //     var script = document.createElement("script");
  //     script.src = "https://cdn.jsdelivr.net/gh/mickidum/acc_toolbar/acctoolbar/acctoolbar.min.js";
  //     (document.getElementsByTagName("head")[0] || document.body || document.documentElement).appendChild(script);

  //     script.onload = () => {
  //       setTimeout(function () {
  //         new MicAccessTool({
  //           buttonPosition: position,
  //           forceLang: language,
  //           // link: 'http://your-awesome-website.com/your-accessibility-declaration.pdf',
  //           // contact: 'mailto:your-mail@your-awesome-website.com',
  //         });
  //       }, 700);
  //     };

  //     // Cleanup on component unmount
  //     return () => {
  //       var scriptToRemove = document.querySelector('script[src="https://cdn.jsdelivr.net/gh/mickidum/acc_toolbar/acctoolbar/acctoolbar.min.js"]');
  //       if (scriptToRemove && scriptToRemove.parentNode) {
  //         scriptToRemove.parentNode.removeChild(scriptToRemove);
  //       }
  //     }
  //   }, [i18n.language]);

  return (
    <>
      <BrowserRouter>
        <CookieConsentBanner />
        {/* <ChatBot /> */}
        <div className="flex flex-col min-h-screen">
          {/* Render the NavBar component */}
          <NavBar />
          {/* Define application routes */}
          <div className="content flex-grow container mx-auto px-4">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </Suspense>
          </div>
          {/* Render the Footer component */}
          <Footer />
          {/* Render Accessibility component */}
          {/* <AccessibilityWidget /> */}
        </div>
      </BrowserRouter>
      {/* <ToastContainer /> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
